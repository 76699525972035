import { FormControl, FormLabel, Select, Text } from "@chakra-ui/react";
import React from "react";

const SelectBox = ({ children, value, onChange, label, error }) => {
  return (
    <FormControl mt={2} flex={1}>
      <FormLabel marginBottom={2}>{label}</FormLabel>
      <Select
        value={value}
        onChange={onChange}
        borderColor={error ? "#f00" : "#EDF2F7"}
      >
        {children}
      </Select>
      {error && (
        <Text fontSize={"sm"} color={"#f00"}>
          {error}
        </Text>
      )}
    </FormControl>
  );
};

export default SelectBox;

{
  /* <Select
title={"Lable"}
value={Obj?.location}
onChange={(e) =>
  setObj({
    ...Obj,
    location: e.target.value,
    locationval: false,
    locationmess: "",
  })
}
>
<option hidden value="">
  Select
</option>
<option value="Remote">Remote</option>
<option value="OnSite">On-Site</option>
<option value="Hybrid">Hybrid</option>
</Select> */
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

export const delete_media = createAsyncThunk("delete_media", async (id) => {
  const body = new FormData();
  body.append("action", "delete");
  body.append("id", id);
  const response = await fetch(base_url + "media.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

export const delete_associate = createAsyncThunk("delete_associate", async (id) => {
  const body = new FormData();
  body.append("action", "delete");
  body.append("id", id);
  const response = await fetch(base_url + "associate_companies.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

export const delete_career = createAsyncThunk("delete_career", async (id) => {
  const body = new FormData();
  body.append("action", "delete");
  body.append("id", id);
  const response = await fetch(base_url + "careers.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

export const delete_user = createAsyncThunk("delete_user", async (id) => {
  const body = new FormData();
  body.append("action", "delete");
  body.append("id", id);
  const response = await fetch(base_url + "employee.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

const otherSlice = createSlice({
  name: "other",
  initialState: {
    sidebar_width: true,
  },
  reducers: {
    fullSliderwidth(state) {
      state.sidebar_width = !state.sidebar_width;
    },
  },
});

export const { fullSliderwidth } = otherSlice.actions;
export default otherSlice.reducer;

import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./screens/auth/Login";
import { Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CustomPopup from "./components/CustomPopup";
import Blog from "./screens/Blog";
import useSessionTimeout from "./Hooks/useSessionTimeout";
import Testimonial from "./screens/Testimonial";
import AddTestimonial from "./screens/Testimonial/AddTestimonial";
import AddBlog from "./screens/Blog/AddBlog";
import Queries from "./screens/Queries";
import Media from "./screens/Media";
import Associate from "./screens/Associate";
import Careers from "./screens/Careers";
import AddMedia from "./screens/Media/AddMedia";
import AddAssociate from "./screens/Associate/AddAssociate";
import AddCareers from "./screens/Careers/AddCareers";
import JobApplicatsDetails from "./screens/Careers/JobApplicatsDetails";
import Employee from "./screens/Employee";
import AddEmployee from "./screens/Employee/AddEmployee";
import { SideBarItems } from "./utils/utils";
import Policy from "./screens/Policy";
import EditPolicy from "./screens/Policy/EditPolicy";

function App() {
  const [sessionExpire1, setSessionExpire1] = useState(false);
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("userId");
  const user = loggedInUser ? JSON.parse(loggedInUser) : {};
  const { permissions, super_admin } = user || {};
  const location = useLocation();

  useEffect(() => {
    if (user === null) {
      if (location.pathname !== "/login") {
        navigate("/login");
      }
      return;
    }
    if (location.pathname !== "/") {
      if (super_admin) {
        navigate("/");
      } else {
        for (const v of SideBarItems) {
          if (permissions?.includes(v?.value)) {
            navigate(v?.path);
            break;
          }
        }
      }
    }
  }, []);

  const handleSessionExpire = () => {
    localStorage.clear();
    navigate("/login");
    setSessionExpire1(true);
  };
  const { sessionExpire } = useSessionTimeout(
    user != null,
    600,
    handleSessionExpire
  );

  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute />}>
          {user && (
            <Route path="/" element={<Layout />}>
              {(permissions?.includes("queries") || super_admin) && (
                <>
                  <Route index element={<Queries />} />
                </>
              )}

              {(permissions?.includes("blog") || super_admin) && (
                <>
                  <Route path="blog" element={<Blog />} />
                  <Route path="add-blog" element={<AddBlog />} />
                </>
              )}
              {(permissions?.includes("media") || super_admin) && (
                <>
                  <Route path="media" element={<Media />} />
                  <Route path="add-media" element={<AddMedia />} />
                </>
              )}
              {(permissions?.includes("testimonial") || super_admin) && (
                <>
                  <Route path="testimonial" element={<Testimonial />} />
                  <Route path="add-testimonial" element={<AddTestimonial />} />
                </>
              )}
              {(permissions?.includes("associate") || super_admin) && (
                <>
                  <Route path="associated" element={<Associate />} />
                  <Route path="add-associated" element={<AddAssociate />} />
                </>
              )}
              {(permissions?.includes("career") || super_admin) && (
                <>
                  <Route path="careers" element={<Careers />} />
                  <Route path="add-careers" element={<AddCareers />} />
                  <Route
                    path="career-details"
                    element={<JobApplicatsDetails />}
                  />
                </>
              )}
              {(permissions?.includes("policy") || super_admin) && (
                <>
                  <Route path="policy" element={<Policy />} />
                  <Route path="edit-policy" element={<EditPolicy />} />
                </>
              )}

              {super_admin && (
                <>
                  <Route path="users" element={<Employee />} />
                  <Route path="add-users" element={<AddEmployee />} />
                </>
              )}
            </Route>
          )}
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <CustomPopup
        isOpen={sessionExpire1}
        onClose={() => {
          setSessionExpire1(false);
          localStorage.clear();
          window.sessionStorage.clear();
          sessionStorage.clear();
          window.location.reload();
        }}
        title={"Alert"}
        mess={"Your session is expired please Login"}
        single_button={true}
        onClick={() => {
          setSessionExpire1(false);
          window.sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem("user");
          window.location.reload();
        }}
        b_name={"OK"}
      />
    </>
  );
}
function PageNotFound() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Heading fontSize={"6xl"} color={"#ffbf00"}>
          404
        </Heading>
        <Heading fontSize={"2xl"} paddingBlock={2}>
          Page Not Found
        </Heading>
        <Text>Oops! You seem to be lost.</Text>
        <Text>
          Go to
          <Link
            to="/login"
            style={{
              textDecorationLine: "underline",
              padding: 5,
            }}
          >
            {" "}
            Home{" "}
          </Link>
          page
        </Text>
      </div>
    </div>
  );
}
export default App;

{
  /* <Route path="blog" element={<Blog />} />
              <Route path="add-blog" element={<AddBlog />} />
              <Route path="testimonial" element={<Testimonial />} />
              <Route path="add-testimonial" element={<AddTestimonial />} />

              <Route path="media" element={<Media />} />
              <Route path="add-media" element={<AddMedia />} />

              <Route path="associated" element={<Associate />} />
              <Route path="add-associated" element={<AddAssociate />} />

              <Route path="careers" element={<Careers />} />
              <Route path="add-careers" element={<AddCareers />} />
              <Route path="career-details" element={<JobApplicatsDetails />} /> 

              <Route path="policy" element={<Policy />} />
              */
}

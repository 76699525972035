import {
  Center,
  Container,
  Flex,
  Image,
  Stack,
  Td,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import HeadingLayout from "../../components/HeadingLayout";
import useFetchApi from "../../Hooks/useFetchApi";
import TableView from "../../components/TableView";
import Loader from "../../components/Loader";
import CustomButton from "../../components/CustomButton";
import { FiEdit3 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Policy = () => {
  const {
    data,
    isLoading,
    refetch: handleFetchData,
  } = useFetchApi({
    path: "policy.php",
    formDataArr: {
      action: "list",
    },
  });
  const navigate = useNavigate();
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <HeadingLayout
        title={"Policy"}
        ListText={""}
        btnNot
        sNot
        showTotal={false}
      />
      <Stack mt={10} w={"full"}>
        {isLoading && data?.data?.length === 0 ? (
          <Loader />
        ) : data?.data?.length === 0 ? (
          <Center bg={"#fff"} borderRadius={10}>
            <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
          </Center>
        ) : (
          <Flex flexDirection={"column"} gap={2}>
            <div
              dangerouslySetInnerHTML={{ __html: data?.data?.description }}
            ></div>
            <Flex justifyContent={"center"} mt={10} mb={10}>
              <CustomButton
                onClick={() => {
                  navigate("/edit-policy", {
                    state: { type: "Edit", item: data?.data },
                  });
                }}
                title={
                  <>
                    <FiEdit3 size={20} cursor={"pointer"} /> Edit
                  </>
                }
              />
            </Flex>
          </Flex>
        )}
      </Stack>
    </Container>
  );
};

export default Policy;

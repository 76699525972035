import { Card, Center, Container, Grid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HeadingLayoutAdd } from "../../components/LayoutUtils";
import { useLocation, useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import SelectImage from "../../components/SelectImage";
import CustomButton from "../../components/CustomButton";
import { add_media } from "../../utils/apis";
import SelectBox from "../../components/SelectBox";

const AddMedia = () => {
  const { state } = useLocation();
  const { type, item } = state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Obj, setObj] = useState({});

  useEffect(() => {
    if (type == "Edit") {
      setObj(item);
    }
  }, []);

  const validation = () => {
    const video_condition =
      Obj.type == "video" && type == "Add"  &&
      (!Obj.video_url || !Obj.video_url.includes("<iframe")) ;

    const image_condition =
      Obj.type == "image" && type == "Add" && (!Obj.image || !Obj.image.name);

    if (!Obj?.title || image_condition || !Obj?.alt_text || video_condition) {
      setObj({
        ...Obj,
        titleval: !Obj?.title,
        titlemess: !Obj?.title ? "Title is required" : "",

        typemess: !Obj?.type ? "Type is required" : "",

        imageval: image_condition,
        imagemess: image_condition ? "Image is required" : "",

        alt_textval: !Obj?.alt_text,
        alt_textmess: !Obj?.alt_text ? "Alt text is required" : "",

        video_urlval: video_condition,
        video_urlmess: video_condition
          ? "Please enter a valid YouTube Embed video URL"
          : "",
      });
      return;
    } else {
      submitData();
    }
  };

  const submitData = async () => {
    setLoading(true);
    try {
      const body = new FormData();
      body.append("action", type == "Edit" ? "update" : "create");
      if (type == "Edit") {
        body.append("id", item?.id);
      }
      body.append("title", Obj?.title);
      body.append("type", Obj?.type);
      body.append("alt_text", Obj?.alt_text);
      if (Obj?.image?.name) {
        body.append("image", Obj?.image);
      }
      if (Obj?.video_url) {
        body.append("video_url", Obj?.video_url);
      }
      const res = await add_media(body);
      if (res.success) {
        navigate(-1);
      }
    } catch (error) {
      console.log("error ===", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <HeadingLayoutAdd type={type} title="Media" />
      <Card bg={"#fff"} mt={2} p={5}>
        <Grid
          mb={10}
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={3}
          width="100%"
          height="100%"
        >
          <CustomInput
            label1={"Title"}
            placeholder1={"Enter Title"}
            value1={Obj.title}
            onChange1={(e) =>
              setObj({
                ...Obj,
                title: e.target.value,
                titleval: false,
                titlemess: "",
              })
            }
            onlyone
            errorBorder1={Obj.titleval}
            error1={Obj.titlemess}
          />
          <SelectBox
            label={"Media Type"}
            value={Obj?.type}
            onChange={(e) =>
              setObj({
                ...Obj,
                type: e.target.value,
                typeval: false,
                typemess: "",
                imageval: false,
                imagemess: "",
                alt_textval: false,
                alt_textmess: "",
                video_urlval: false,
                video_urlmess: "",
              })
            }
            error={Obj.typemess}
          >
            <option value="">Select</option>
            <option value="video">Video</option>
            <option value="image">Image</option>
          </SelectBox>

          {Obj.type && (
            <>
              {Obj.type == "video" ? (
                <>
                  <CustomInput
                    label1={"Video URL"}
                    placeholder1={"Enter Video URL"}
                    value1={Obj.video_url}
                    onChange1={(e) =>
                      setObj({
                        ...Obj,
                        video_url: e.target.value,
                        video_urlval: false,
                        video_urlmess: "",
                      })
                    }
                    onlyone
                    errorBorder1={Obj.video_urlval}
                    error1={Obj.video_urlmess}
                  />
                </>
              ) : (
                <>
                  <SelectImage
                    errorBorder={Obj?.imagemess ? "#f00" : ""}
                    error={Obj?.imagemess}
                    mt={2}
                    label={"Media Image"}
                    filename={Obj?.image?.name}
                    onChange={(e) =>
                      setObj({
                        ...Obj,
                        image: e.target.files[0],
                        imagemess: "",
                      })
                    }
                  />
                </>
              )}
              <CustomInput
                label1={"Alt Text"}
                placeholder1={"Enter Alt Text"}
                value1={Obj.alt_text}
                onChange1={(e) =>
                  setObj({
                    ...Obj,
                    alt_text: e.target.value,
                    alt_textval: false,
                    alt_textmess: "",
                  })
                }
                onlyone
                errorBorder1={Obj.alt_textval}
                error1={Obj.alt_textmess}
              />
            </>
          )}
        </Grid>
        <Center mt={10}>
          <CustomButton
            title={"Submit"}
            loading={loading}
            onClick={() => validation()}
          />
        </Center>
      </Card>
    </Container>
  );
};

export default AddMedia;

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import store from "../redux/store";

const useDeleteItem = (handleFetchData, deleteFunction, id) => {
  const onDelete = useCallback(async () => {
    if (!id) return;

    try {
      const data = await store.dispatch(deleteFunction(id));
      const res = data?.payload;

      if (res?.success) {
        toast.success(res.message);
        handleFetchData();
      } else {
        toast.error(res?.message || "Failed to delete item");
      }
    } catch (error) {
      toast.error("An error occurred while deleting");
    }
  }, [id]);

  return { onDelete };
};

export default useDeleteItem;

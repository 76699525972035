import { createAsyncThunk } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

export const delete_testimonial = createAsyncThunk("delete_testimonial", async (id) => {
  const body = new FormData();
  body.append("action", "delete");
  body.append("id", id);
  const response = await fetch(base_url + "testimonial.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});



import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Description, HeadingLayoutAdd } from "../../components/LayoutUtils";
import { Card, Center, Container, Grid, Select } from "@chakra-ui/react";
import CustomInput from "../../components/CustomInput";
import SelectBox from "../../components/SelectBox";
import CustomButton from "../../components/CustomButton";
import { add_careers } from "../../utils/apis";
import moment from "moment";

const AddCareers = () => {
  const { state } = useLocation();
  const { type, item } = state;
  const navigate = useNavigate();
  const [Obj, setObj] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type == "Edit") {
      setObj(item);
    }
  }, []);

  const validation = () => {
    if (
      !Obj?.title ||
      !Obj?.location ||
      !Obj?.job_description ||
      !Obj?.job_type ||
      !Obj?.deadline ||
      !Obj?.overview ||
      !Obj?.responsibility ||
      !Obj?.qualification
    ) {
      setObj({
        ...Obj,
        titleval: !Obj?.title,
        titlemess: !Obj?.title ? "Title is required" : "",

        locationval: !Obj?.location,
        locationmess: !Obj?.location ? "Location is required" : "",

        job_descriptionval: !Obj?.job_description,
        job_descriptionmess: !Obj?.job_description
          ? "Job Description is required"
          : "",

        job_typeval: !Obj?.job_type,
        job_typemess: !Obj?.job_type ? "Job Type is required" : "",

        deadlineval: !Obj?.deadline,
        deadlinemess: !Obj?.deadline ? "Deadline is required" : "",

        overviewval: !Obj?.overview,
        overviewmess: !Obj?.overview ? "Overview is required" : "",

        responsibilityval: !Obj?.responsibility,
        responsibilitymess: !Obj?.responsibility
          ? "Responsibility is required"
          : "",

        qualificationval: !Obj?.qualification,
        qualificationmess: !Obj?.qualification
          ? "Qualification is required"
          : "",
      });
      return;
    } else {
      submitData();
    }
  };

  const submitData = async () => {
    setLoading(true);
    try {
      const body = new FormData();
      body.append("action", type == "Edit" ? "update" : "create");
      if (type == "Edit") {
        body.append("id", item?.id);
      }
      body.append("title", Obj?.title);
      body.append("location", Obj?.location);
      body.append("job_type", Obj?.job_type);
      body.append("job_description", Obj?.job_description);
      body.append("deadline", Obj?.deadline);
      body.append("overview", Obj?.overview);
      body.append("perks", Obj?.perks);
      body.append("qualification", Obj?.qualification);
      body.append("responsibility", Obj?.responsibility);
      const res = await add_careers(body);
      if (res.success) {
        navigate(-1);
      }
    } catch (error) {
      console.log("error ===", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <HeadingLayoutAdd type={type} title="Career" />
      <Card bg={"#fff"} mt={2} p={5}>
        <Grid
          mb={10}
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={3}
          width="100%"
          height="100%"
        >
          <CustomInput
            label1={"Title"}
            placeholder1={"Enter Title"}
            value1={Obj.title}
            onChange1={(e) =>
              setObj({
                ...Obj,
                title: e.target.value,
                titleval: false,
                titlemess: "",
              })
            }
            onlyone
            errorBorder1={Obj.titleval}
            error1={Obj.titlemess}
          />

          <CustomInput
            label1={"Location"}
            placeholder1={"Enter Location"}
            value1={Obj.location}
            onChange1={(e) =>
              setObj({
                ...Obj,
                location: e.target.value,
                locationval: false,
                locationmess: "",
              })
            }
            onlyone
            errorBorder1={Obj.locationval}
            error1={Obj.locationmess}
          />
          {/* job description */}
          <SelectBox
            label={"job description"}
            value={Obj?.job_description}
            error={Obj?.job_descriptionmess}
            onChange={(e) =>
              setObj({
                ...Obj,
                job_description: e.target.value,
                job_descriptionval: false,
                job_descriptionmess: "",
              })
            }
          >
            <option hidden value="">
              Select
            </option>
            <option value="Full Time">Full Time</option>
            <option value="Part Time">Part Time</option>
            <option value="Freelance">Freelance</option>
          </SelectBox>

          <SelectBox
            label={"Job Type"}
            value={Obj?.job_type}
            error={Obj?.job_typemess}
            onChange={(e) =>
              setObj({
                ...Obj,
                job_type: e.target.value,
                job_typeval: false,
                job_typemess: "",
              })
            }
          >
            <option hidden value="">
              Select
            </option>
            <option value="Remote">Remote</option>
            <option value="On-Site">On-Site</option>
            <option value="Hybrid">Hybrid</option>
          </SelectBox>

          <CustomInput
            label1={"Application Deadline"}
            placeholder1={"Enter Application Deadline"}
            type1={"date"}
            min1={moment(new Date()).format("YYYY-MM-DD")}
            value1={Obj.deadline}
            onChange1={(e) =>
              setObj({
                ...Obj,
                deadline: e.target.value,
                deadlineval: false,
                deadlinemess: "",
              })
            }
            onlyone
            errorBorder1={Obj.deadlineval}
            error1={Obj.deadlinemess}
          />

          <CustomInput
            label1={"Job Overview "}
            placeholder1={"Enter Job Overview "}
            area1
            value1={Obj.overview}
            onChange1={(e) =>
              setObj({
                ...Obj,
                overview: e.target.value,
                overviewval: false,
                overviewmess: "",
              })
            }
            onlyone
            errorBorder1={Obj.overviewval}
            error1={Obj.overviewmess}
          />
          <Description
            label={"Responsibilities :"}
            value={Obj?.responsibility}
            onChange={(content) =>
              setObj((prev) => ({
                ...prev,
                responsibility: content,
                responsibilityMess: "",
              }))
            }
            error={Obj?.responsibilityMess}
          />
          <Description
            label={"Qualification :"}
            value={Obj?.qualification}
            onChange={(content) =>
              setObj((prev) => ({
                ...prev,
                qualification: content,
                qualificationmess: "",
              }))
            }
            error={Obj?.qualificationmess}
          />
        </Grid>

        <Center mt={10}>
          <CustomButton
            title={"Submit"}
            loading={loading}
            onClick={() => validation()}
            
          />
        </Center>
      </Card>
    </Container>
  );
};

export default AddCareers;

import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Select,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import useTableControls from "../../Hooks/useTableControls";
import useFetchApi from "../../Hooks/useFetchApi";
import { BackButton } from "../../components/LayoutUtils";
import TableView from "../../components/TableView";
import Loader from "../../components/Loader";
import CustomPopup from "../../components/CustomPopup";
import moment from "moment";
import { FiEdit3, FiEye } from "react-icons/fi";
import ImageModal from "../../components/ImageModal";
import { add_careers } from "../../utils/apis";
import CustomInput from "../../components/CustomInput";
import CustomToolTip from "../../components/CustomTooltip";

const JobApplicatsDetails = () => {
  // job_applicants(job_id,page,limit)
  const {
    rows,
    setRows,
    page,
    setPage,
    id: uid,
    setId,
    search,
    debouncedSearch,
    url,
    setUrl,
    text,
    setText,
    loading,
    setLoading,
  } = useTableControls();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { state } = useLocation();
  const { id } = state;

  const {
    isOpen: isCOpen,
    onOpen: onCOpen,
    onClose: onCClose,
  } = useDisclosure();
  const {
    isOpen: isCOpen1,
    onOpen: onCOpen1,
    onClose: onCClose1,
  } = useDisclosure();

  const {
    data,
    isLoading,
    refetch: handleFetchData,
  } = useFetchApi({
    path: "careers.php",
    formDataArr: {
      action: "job_applicants",
      limit: rows,
      page: page,
      job_id: id,
      value: debouncedSearch,
    },
  });

  // update_applicant_status( id, status)

  const update_status = async (id, text) => {
    try {
      const body = new FormData();
      body.append("action", "update_applicant_status");
      body.append("id", id);
      body.append("status", text);
      add_careers(body).then((v) => {
        if (v.success) {
          handleFetchData();
        }
      });
    } catch (error) {
      console.log("error ===", error);
    }
  };

  const update_comment = async (id, text) => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "comment");
      body.append("id", id);
      body.append("comment", text);
      add_careers(body).then((v) => {
        if (v.success) {
          handleFetchData();
          onCClose();
        }
      });
    } catch (error) {
      console.log("error ===", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Applicant List</Heading>
        <BackButton />
      </Flex>
      <Flex gap={2} align={"center"}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Applicant : {data?.totalCount}
        </Text>
      </Flex>

      {isLoading && data?.data?.length === 0 ? (
        <Loader />
      ) : data?.data?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          pagination={true}
          initialValue={page * rows - rows + 1}
          rows={rows}
          page={page}
          onNext={() => {
            if (
              page <
              Math.ceil((search ? data?.searchCount : data?.totalCount) / rows)
            ) {
              setPage(page + 1);
            }
          }}
          onPrevious={() => {
            if (page > 1) {
              setPage(page - 1);
            }
          }}
          onChange={(e) => {
            setPage(1);
            setRows(e.target.value);
          }}
          total_value={search ? data?.searchCount : data?.totalCount}
          headData={[
            "S.No.",
            "Full Name",
            "Email",
            "Mobile",
            "Linkdin",
            "Status",
            "Resume / CV",
            "Comment",
          ]}
          body={data?.data?.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + 1 + rows * (page - 1)}</Td>
              <Td textAlign={"center"}>{v?.full_name}</Td>
              <Td textAlign={"center"}>{v?.email}</Td>
              <Td textAlign={"center"}>{v?.phone_number}</Td>
              <Td textAlign={"center"}>
                <p
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(v?.linkedin_profile, "_blank")}
                >
                  link
                </p>
              </Td>
              <Td textAlign={"center"} w={"36"}>
                <Select
                  w={"32"}
                  size={"sm"}
                  borderRadius={"md"}
                  value={v.status}
                  onChange={(e) => update_status(v.id, e.target.value)}
                >
                  <option value="1">Knew</option>
                  <option value="2">In Progress</option>
                  <option value="3">On Hold</option>
                  <option value="4">Recruit</option>
                  <option value="5">Rejected</option>
                </Select>
              </Td>

              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiEye
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      onOpen();
                      setUrl(v?.resume);
                    }}
                  />
                </Flex>
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={2} justifyContent={"center"}>
                  <CustomToolTip
                    button={
                      <FiEye
                        size={20}
                        cursor={"pointer"}
                        onClick={() => {
                          setId(v.id);
                          setText(v.comment);
                          onCOpen1();
                        }}
                      />
                    }
                    mess={"View Comment"}
                  />
                  <CustomToolTip
                    button={
                      <FiEdit3
                        size={20}
                        cursor={"pointer"}
                        onClick={() => {
                          setId(v.id);
                          setText(v.comment);
                          onCOpen();
                        }}
                      />
                    }
                    mess={"Edit Comment"}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}

      <ImageModal
        // vid={true}
        isOpen={isOpen}
        onClose={onClose}
        url={url}
      />

      <CustomPopup
        isOpen={isCOpen1}
        onClose={onCClose1}
        title={"Comment"}
        single_button
        mess={
          <Stack>
            {text.length == 0 ? (
              <Text>No Comment, Please add comment</Text>
            ) : (
              <Text>{text}</Text>
            )}
          </Stack>
        }
      />

      <CustomPopup
        isOpen={isCOpen}
        onClose={onCClose}
        title={"Comment"}
        b_name={"Update"}
        onClick={() => update_comment(uid, text)}
        b_color={"#FFBF00"}
        loading={loading}
        mess={
          <Stack>
            {text.length == 0 && <Text>No Comment, Please add comment</Text>}
            <CustomInput
              area1
              onlyone
              value1={text}
              onChange1={(e) => setText(e.target.value)}
              placeholder1={"Enter Comment..."}
            />
          </Stack>
        }
      />
    </Container>
  );
};

export default JobApplicatsDetails;

// import { Container, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react";
// import React, { useEffect, useState } from "react";
// import CustomButton from "../../components/CustomButton";
// import { useNavigate } from "react-router-dom";
// import useFetchApi from "../../Hooks/useFetchApi";
// import useDebounce from "../../Hooks/useDebounce";

import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Select,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import useFetchApi from "../../Hooks/useFetchApi";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import ImageModal from "../../components/ImageModal";
import { toast } from "react-toastify";
import store from "../../redux/store";
import TableHeader from "../../components/TableHeader";
import useTableControls from "../../Hooks/useTableControls";
import { delete_blog } from "../../redux/slice/BlogSlice";
import useDeleteItem from "../../Hooks/useDeleteItem";
import HeadingLayout from "../../components/HeadingLayout";
import { delete_career } from "../../redux/slice/otherSlice";
import moment from "moment";
import { add_careers } from "../../utils/apis";

const Careers = () => {
  const {
    rows,
    setRows,
    page,
    setPage,
    search,
    setSearch,
    debouncedSearch,
    id,
    setId,
    url,
    setUrl,
    loading,
    setLoading,
  } = useTableControls();

  // Hooks
  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    data,
    isLoading,
    refetch: handleFetchData,
  } = useFetchApi({
    path: "careers.php",
    formDataArr: {
      action: "list",
      limit: rows,
      page: page,
      value: debouncedSearch,
    },
  });

  const update_status = async (id, text) => {
    try {
      // setLoading(true);
      const body = new FormData();
      body.append("action", "update_status");
      body.append("id", id);
      body.append("status", text);
      add_careers(body).then((v) => {
        if (v.success) {
          handleFetchData();
        }
      });
    } catch (error) {
      console.log("error ===", error);
    } finally {
      setLoading(false);
    }
  };

  const { onDelete } = useDeleteItem(handleFetchData, delete_career, id);

  useEffect(() => {
    handleFetchData();
  }, [page, rows, debouncedSearch]);

  const navigate = useNavigate();

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <HeadingLayout
        title={"Career"}
        onClick={() => navigate("/add-careers", { state: { type: "Add" } })}
        totalCount={data?.totalCount}
      />

      {data?.totalCount > 0 && (
        <TableHeader
          filter={<></>}
          exprtData={false}
          placeholder={`Search Career`}
          value={search}
          onSerchChange={(v) => {
            setPage(1);
            setRows(10);
            setSearch(v.target.value);
          }}
        />
      )}

      {isLoading && data?.data?.length === 0 ? (
        <Loader />
      ) : data?.data?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          pagination={true}
          initialValue={page * rows - rows + 1}
          rows={rows}
          page={page}
          onNext={() => {
            if (
              page <
              Math.ceil((search ? data?.searchCount : data?.totalCount) / rows)
            ) {
              setPage(page + 1);
            }
          }}
          onPrevious={() => {
            if (page > 1) {
              setPage(page - 1);
            }
          }}
          onChange={(e) => {
            setPage(1);
            setRows(e.target.value);
          }}
          total_value={search ? data?.searchCount : data?.totalCount}
          headData={[
            "S.No.",
            "Title",
            "Location",
            "Job Description",
            "Job Type",
            "Deadline",
            "Job Overview",
            "Responsibilities",
            "Qualifications",
            "Total Application",
            "Status",
            "Actions",
          ]}
          body={data?.data?.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + 1 + rows * (page - 1)}</Td>
              <Td textAlign={"center"}>{v?.title}</Td>
              <Td textAlign={"center"}>{v?.location}</Td>
              <Td textAlign={"center"}>{v?.job_description}</Td>
              <Td textAlign={"center"}>{v?.job_type}</Td>
              <Td textAlign={"center"}>
                {moment(v?.deadline).format("DD-MMM-YYYY")}
              </Td>
              <Td
                style={{
                  maxWidth: "300px",
                  width: "300px",
                  // maxHeight: "200px",
                  minHeight: "200px",
                  overflowY: "auto",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                  display: "block",
                  textAlign: "center",
                }}
                textAlign={"center"}
              >
                {v?.overview}
              </Td>

              <Td textAlign={"center"}>
                <p
                  style={{
                    maxWidth: "300px",
                    maxHeight: "100%",
                    overflowY: "auto",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                  }}
                  dangerouslySetInnerHTML={{ __html: v?.responsibility }}
                ></p>
              </Td>
              <Td textAlign={"center"}>
                <p
                  style={{
                    maxWidth: "300px",
                    maxHeight: "100%",
                    overflowY: "auto",
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                  }}
                  dangerouslySetInnerHTML={{ __html: v?.qualification }}
                ></p>
              </Td>
              <Td textAlign={"center"}>
                {v?.total_applicants === 0 ? (
                  "No applications yet"
                ) : (
                  <p
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate("/career-details", { state: { id: v?.id } })
                    }
                  >
                    {v?.total_applicants}
                  </p>
                )}
              </Td>

              <Td textAlign={"center"} w={"36"}>
                <Select
                  w={"32"}
                  size={"sm"}
                  borderRadius={"md"}
                  value={v.status}
                  onChange={(e) => update_status(v.id, e.target.value)}
                >
                  <option value="1">Open</option>
                  <option value="2">On Hold</option>
                  <option value="3">Closed</option>
                  <option value="4">Canceled</option>
                </Select>
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      onOpen();
                      setId(v?.id);
                    }}
                  />
                  <FiEdit3
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      navigate("/add-careers", {
                        state: { type: "Edit", item: v },
                      });
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}

      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? You want to delete Career"}
        onClick={() => {
          onDelete();
          onClose();
        }}
      />
    </Container>
  );
};

export default Careers;

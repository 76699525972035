import React, { useEffect, useState, useRef } from "react";

import CustomButton from "./CustomButton";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
// import { FaRegStarHalfStroke } from "react-icons/fa6";

import { FaStar } from "react-icons/fa";
import { IoStarHalf } from "react-icons/io5";
import { Flex, FormLabel, Text } from "@chakra-ui/react";

import $ from "jquery";
import "summernote/dist/summernote-lite.css";
import "summernote/dist/summernote-lite.js";
import HeadingText from "./HeadingText";

export const BackButton = React.memo(() => {
  const navigate = useNavigate();
  return (
    <CustomButton
      title={"Back"}
      icon={<BiArrowBack size={24} />}
      onClick={() => navigate(-1)}
    />
  );
});

export const FeedBack = React.memo(({ data, setData, title, error }) => {
  const [hover, setHover] = useState(null);
  return (
    <Flex direction={"column"}>
      {title && <FormLabel marginBottom={2}>{title}</FormLabel>}
      <div className="Feed-main">
        <div className="feed-icons">
          {[...Array(5)].map((star, index) => {
            const starValue = index + 1;
            return (
              <label key={index}>
                <FaStar
                  className="feed-icon"
                  color={
                    starValue <= (hover || data?.rating) ? "#ffc107" : "#e4e5e9"
                  }
                  size={30}
                  value={starValue}
                  onClick={() =>
                    setData({ ...data, rating: starValue, ratingmess: "" })
                  }
                  onMouseEnter={() => setHover(starValue)}
                  onMouseLeave={() => setHover(null)}
                />
              </label>
            );
          })}
        </div>
      </div>
      {error && (
        <Text fontSize={"sm"} color={"#f00"}>
          {error}
        </Text>
      )}
    </Flex>
  );
});

export const FeedBackbar = React.memo(({ rating }) => {
  return (
    <div className="feed-icons" style={{ margin: 0 }}>
      {[...Array(5)].map((star, index) => {
        const starValue = index + 1;

        // Render a full star, half star, or empty star based on the rating
        return (
          <label key={index}>
            {starValue <= rating ? (
              <FaStar
                cursor={"none"}
                className="feed-icon"
                color="#ffc107"
                size={16}
              />
            ) : starValue - 0.5 <= rating ? (
              <IoStarHalf
                cursor={"none"}
                className="feed-icon"
                color="#ffc107"
                size={18}
              />
            ) : (
              <FaStar
                cursor={"none"}
                className="feed-icon"
                color="#e4e5e9"
                size={16}
              />
            )}
          </label>
        );
      })}
    </div>
  );
});

export const Description = React.memo(
  ({ value, onChange, height = 350, disabled, label, error }) => {
    const editorRef = useRef(null);

    useEffect(() => {
      // Initialize Summernote
      $(editorRef.current).summernote({
        height: height,
        toolbar: [
          ["style", ["style"]],
          ["font", ["bold", "underline", "clear"]],
          ["color", ["color"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["table", ["table"]],
          ["insert", ["link", "picture"]],
          ["view", ["fullscreen", "codeview"]],
        ],
        callbacks: {
          onChange: function (contents) {
            onChange && onChange(contents); // Trigger onChange callback
          },
          onKeyup: function () {
            const code = $(editorRef.current).summernote("code");
            onChange && onChange(code);
          },
        },
      });

      // Set initial value
      if (value) {
        $(editorRef.current).summernote("code", value);
      }

      return () => {
        $(editorRef.current).summernote("destroy"); // Cleanup
      };
    }, []);

    useEffect(() => {
      if (editorRef.current) {
        const $editor = $(editorRef.current);
        const currentCode = $editor.summernote("code");

        if (currentCode !== value) {
          $editor.summernote("code", value || "");

          // Move cursor to the end of the content
          setTimeout(() => {
            $editor.summernote("focus"); // Ensure focus is on the editor
            document.execCommand("selectAll", false, null); // Select all text
            document.getSelection()?.collapseToEnd(); // Move cursor to the end
          }, 10);
        }
      }
    }, [value]);

    return (
      <div className="summernote-editor-container">
        {label && <FormLabel marginBottom={2}>{label}</FormLabel>}
        <textarea disabled={disabled} ref={editorRef} />
        {/* {error && <p className="text-red-500 text-sm mt-1">{error}</p>} */}
        {error && (
          <Text fontSize={"sm"} color={"#f00"}>
            {error}
          </Text>
        )}
      </div>
    );
  }
);

export const HeadingLayoutAdd = React.memo(({ type, title }) => {
  return (
    <Flex justify={"space-between"}>
      <HeadingText title={`${type} ${title}`} />
      <BackButton />
    </Flex>
  );
});

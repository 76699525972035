import React, { useEffect, useState } from "react";
import {
  Card,
  Center,
  Checkbox,
  Container,
  Flex,
  Grid,
} from "@chakra-ui/react";
import { HeadingLayoutAdd } from "../../components/LayoutUtils";
import { useLocation, useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import { FiEye, FiEyeOff } from "react-icons/fi";
import CustomButton from "../../components/CustomButton";
import { base_url, SideBarItems } from "../../utils/utils";
import { toast } from "react-toastify";

const AddEmployee = () => {
  const { state } = useLocation();
  const { type, item } = state;
  const navigate = useNavigate();
  const [Obj, setObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    if (type == "Edit" || type == "View") {
      setObj({ ...item, permissions: item?.permissions.split(",") });
    }
  }, []);

  const validation = () => {
    const errors = {
      usernamemess: !Obj?.username,
      usernamemess: !Obj?.username ? "User Name is required" : "",

      passwordval: !Obj?.password && type == "Add",
      passwordmess:
        !Obj?.password && type == "Add" ? "Password is required" : "",

      permissionsval: !Obj?.permissions || Obj?.permissions?.length === 0,
      permissionsmess:
        !Obj?.permissions || Obj?.permissions?.length === 0
          ? "At least one permission is required"
          : "",
    };

    if (errors.titleval || errors.passwordval || errors.permissionsval) {
      setObj((prev) => ({ ...prev, ...errors }));
      return;
    }
    submitData();
  };

  const submitData = async () => {
    setLoading(true);
    try {
      const body = new FormData();
      body.append("action", type == "Edit" ? "update" : "create");
      if (type == "Edit") {
        body.append("id", item?.id);
      }
      body.append("username", Obj?.username);
      body.append("password", Obj?.password);
      body.append("permissions", Obj?.permissions?.join(","));
      const response = await fetch(base_url + "employee.php", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      if (res.success) {
        navigate(-1);
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error ===", error);
    } finally {
      setLoading(false);
    }
  };


  // User Name , Password, Permissions ,
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <HeadingLayoutAdd type={type} title="User" />
      <Card bg={"#fff"} mt={2} p={5}>
        <Grid
          mb={10}
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={3}
          width="100%"
          height="100%"
        >
          <CustomInput
            disabled1={type == "View"}
            label1={"User Name"}
            placeholder1={"Enter User Name"}
            value1={Obj.username}
            onChange1={(e) =>
              setObj({
                ...Obj,
                username: e.target.value,
                usernameval: false,
                usernamemess: "",
              })
            }
            onlyone
            errorBorder1={Obj.usernameval}
            error1={Obj.usernamemess}
          />
          {type != "View" && (
            <CustomInput
              disabled1={type == "View"}
              label1={"Password"}
              placeholder1={"Enter Password"}
              type1={show ? "text" : "password"}
              value1={Obj.password}
              onChange1={(e) =>
                setObj({
                  ...Obj,
                  password: e.target.value,
                  passwordval: false,
                  passwordmess: "",
                })
              }
              onlyone
              errorBorder1={Obj.passwordval}
              error1={Obj.passwordmess}
              right={
                !show ? (
                  <FiEye
                    cursor={"pointer"}
                    size={18}
                    onClick={() => setShow(!show)}
                  />
                ) : (
                  <FiEyeOff
                    cursor={"pointer"}
                    size={18}
                    onClick={() => setShow(!show)}
                  />
                )
              }
            />
          )}
        </Grid>
        <Flex gap={4}>
          {SideBarItems.map(({ label, status, value }, index) => (
            <>
              {status && (
                <Checkbox
                  disabled={type == "View"}
                  key={index}
                  isChecked={Obj?.permissions?.includes(value) || false}
                  onChange={() => {
                    setObj((prev) => ({
                      ...prev,
                      permissions: prev?.permissions?.includes(value)
                        ? prev?.permissions?.filter((item) => item != value) // Remove value
                        : [...(prev?.permissions ?? []), value], // Add value
                    }));
                  }}
                >
                  {label}
                </Checkbox>
              )}
            </>
          ))}
        </Flex>
        {type != "View" && (
          <Center mt={10}>
            <CustomButton
              title={"Submit"}
              loading={loading}
              onClick={() => validation()}
              
            />
          </Center>
        )}
      </Card>
    </Container>
  );
};

export default AddEmployee;

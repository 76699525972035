import { Center, Container, Flex, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Description, HeadingLayoutAdd } from "../../components/LayoutUtils";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { base_url } from "../../utils/utils";
import { toast } from "react-toastify";

const EditPolicy = () => {
  const [Obj, setObj] = useState({});
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { type, item } = state;
  useEffect(() => {
    if (type == "Edit") {
      setObj(item);
    }
  }, []);

  const submitData = async () => {
    if (!Obj.description) {
      setObj({ ...Obj, descriptionMess: "Please Enter Description" });
    } else {
      setLoading(true);
      try {
        // update(description,id)
        const body = new FormData();
        body.append("action", type == "Edit" ? "update" : "create");
        if (type == "Edit") {
          body.append("id", item?.id);
        }
        body.append("description", Obj?.description);
        const response = await fetch(base_url + "policy.php", {
          method: "POST",
          body: body,
        });
        const res = await response.json();
        if (res.success) {
          navigate(-1);
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.log("error ===", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <HeadingLayoutAdd type={"Edit"} title="Policy" />
      <Flex gap={10} flexDirection={"column"} mt={10}>
        <Description
          label={"Description :"}
          value={Obj?.description}
          onChange={(content) =>
            setObj((prev) => ({
              ...prev,
              description: content,
              descriptionMess: "",
            }))
          }
          error={Obj?.descriptionMess}
        />
        <Center>
          <CustomButton
            title={"Submit"}
            loading={loading}
            onClick={() => submitData()}
          />
        </Center>
      </Flex>
    </Container>
  );
};

export default EditPolicy;

import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Select,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import useFetchApi from "../../Hooks/useFetchApi";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import TableHeader from "../../components/TableHeader";
import useDebounce from "../../Hooks/useDebounce";
import CustomToolTip from "../../components/CustomTooltip";
import { FiEdit3, FiEye } from "react-icons/fi";
import { update_queries } from "../../utils/apis";
import { toast } from "react-toastify";
import CustomPopup from "../../components/CustomPopup";
import CustomInput from "../../components/CustomInput";
import HeadingLayout from "../../components/HeadingLayout";
import useTableControls from "../../Hooks/useTableControls";

const headData = ["Full Name", "Mobile", "Email", "State", "City", "Query"];

const Queries = () => {
  const {
    rows,
    setRows,
    page,
    setPage,
    search,
    setSearch,
    debouncedSearch,
    id,
    setId,
    value,
    setValue,
    text,
    setText,
    loading,
    setLoading,
  } = useTableControls();

  const {
    isOpen: isCOpen,
    onOpen: onCOpen,
    onClose: onCClose,
  } = useDisclosure();
  const {
    isOpen: isCOpen1,
    onOpen: onCOpen1,
    onClose: onCClose1,
  } = useDisclosure();

  const {
    data,
    isLoading,
    refetch: handleFetchData,
  } = useFetchApi({
    path: "contact_us.php",
    formDataArr: {
      action: "list",
      limit: rows,
      page: page,
      type: value,
      value: debouncedSearch,
    },
  });

  useEffect(() => {
    handleFetchData();
  }, [page, rows, debouncedSearch]);

  const update_status = async (id, text) => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "status");
      body.append("id", id);
      body.append("status", text);
      update_queries(body).then((v) => {
        if (v.success) {
          handleFetchData();
        }
      });
    } catch (error) {
      console.log("error ===", error);
    } finally {
      setLoading(false);
    }
  };

  const update_comment = async (id, text) => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "comment");
      body.append("id", id);
      body.append("comment", text);
      update_queries(body).then((v) => {
        if (v.success) {
          handleFetchData();
          onCClose();
        }
      });
    } catch (error) {
      console.log("error ===", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <HeadingLayout
        title={"Queries"}
        sNot={true}
        btnNot={true}
        // onClick={() => navigate("/add-blog", { state: { type: "Add" } })}
        totalCount={data?.totalCount}
      />

      {data?.totalCount > 0 && (
        <TableHeader
          filterValue={value}
          exprtData={false}
          placeholder={`Search Queries`}
          value={search}
          onSerchChange={(v) => {
            setPage(1);
            setRows(10);
            setSearch(v.target.value);
          }}
          data={["All", ...headData]}
          onChange={(e) => {
            setSearch("");
            setPage(1);
            setRows(10);
            setValue(e.target.value);
          }}
        />
      )}
      {isLoading && data?.data?.length === 0 ? (
        <Loader />
      ) : data?.data?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <>
          <TableView
            pagination={true}
            initialValue={page * rows - rows + 1}
            rows={rows}
            page={page}
            onNext={() => {
              if (
                page <
                Math.ceil(
                  (search ? data?.searchCount : data?.totalCount) / rows
                )
              ) {
                setPage(page + 1);
              }
            }}
            onPrevious={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
            onChange={(e) => {
              setPage(1);
              setRows(e.target.value);
            }}
            total_value={search ? data?.searchCount : data?.totalCount}
            headData={["S.No.", ...headData, "Status", "Comment"]}
            body={data?.data?.map((v, i) => (
              <Tr key={i}>
                <Td textAlign={"center"}>{i + 1 + rows * (page - 1)}</Td>
                <Td textAlign={"center"}>
                  {v?.first_name} {v?.last_name}
                </Td>
                <Td textAlign={"center"}>{v?.mobile}</Td>
                <Td textAlign={"center"}>{v?.email}</Td>
                <Td textAlign={"center"}>{v?.state || "-"}</Td>
                <Td textAlign={"center"}>{v?.city || "-"}</Td>
                <Td textAlign={"center"}>{v?.message}</Td>
                <Td textAlign={"center"} w={"36"}>
                  <Select
                    w={"32"}
                    size={"sm"}
                    borderRadius={"md"}
                    value={v.status == "" ? "InProcess" : v.status}
                    onChange={(e) => update_status(v.id, e.target.value)}
                  >
                    <option value="InProcess">In Process</option>
                    <option value="Not Responding">Not Responding</option>
                    <option value="Converted">Converted</option>
                    <option value="Not Intrested">Not Intrested</option>
                  </Select>
                </Td>
                <Td textAlign={"center"}>
                  <Flex gap={2}>
                    <CustomToolTip
                      button={
                        <FiEye
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            setId(v.id);
                            setText(v.comment);
                            onCOpen1();
                          }}
                        />
                      }
                      mess={"View Comment"}
                    />
                    <CustomToolTip
                      button={
                        <FiEdit3
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            setId(v.id);
                            setText(v.comment);
                            onCOpen();
                          }}
                        />
                      }
                      mess={"Edit Comment"}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          />
        </>
      )}

      <CustomPopup
        isOpen={isCOpen1}
        onClose={onCClose1}
        title={"Comment"}
        single_button
        mess={
          <Stack>
            {text.length == 0 ? (
              <Text>No Comment, Please add comment</Text>
            ) : (
              <Text>{text}</Text>
            )}
          </Stack>
        }
      />
      <CustomPopup
        isOpen={isCOpen}
        onClose={onCClose}
        title={"Comment"}
        b_name={"Update"}
        onClick={() => update_comment(id, text)}
        b_color={"#FFBF00"}
        loading={loading}
        mess={
          <Stack>
            {text.length == 0 && <Text>No Comment, Please add comment</Text>}
            <CustomInput
              area1
              onlyone
              value1={text}
              onChange1={(e) => setText(e.target.value)}
              placeholder1={"Enter Comment..."}
            />
          </Stack>
        }
      />
    </Container>
  );
};

export default Queries;

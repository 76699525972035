import { Button, Center, Container, Flex, Image, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import CustomInput from "../../components/CustomInput";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { login_api } from "../../utils/apis";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { SideBarItems } from "../../utils/utils";

const Login = () => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const onLogin = () => {
    if (!id || !password) {
      toast.info("Please enter user id and password");
      return;
    }
    const body = new FormData();
    body.append("username", id);
    body.append("password", password);
    login_api(body, setLoading).then((v) => {
      if (v.success) {
        navigate("/");
        const permissions = v?.permissions;
        if (v.super_admin) {
          navigate("/");
        } else {
          for (const v of SideBarItems) {
            if (permissions?.includes(v?.value)) {
              navigate(v?.path);
              break; // Stop looping once a match is found
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    user != null && navigate("/");
  }, []);
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        onLogin();
      }
    },
    [id, password]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);

  return (
    <Container
      bg={"#f1f1f1"}
      h={"100vh"}
      w={"full"}
      maxW={"full"}
      centerContent
      justifyContent={"center"}
    >
      <Flex
        backgroundColor={"#fff"}
        maxW={"container.sm"}
        w={"full"}
        shadow={"base"}
        borderRadius={"xl"}
        overflow={"hidden"}
        direction={{ base: "column", sm: "row" }}
      >
        <Image
          src="bg.jpg"
          alt="login"
          w={{ base: "full", sm: "400px" }}
          maxW={{ base: "full", sm: "250px" }}
          maxH={{ base: "200px", sm: "xs" }}
        ></Image>
        <Container w={"full"} maxW={"full"} p={5}>
          {/* <Heading fontSize={"lg"}>Login</Heading> */}
          <CustomInput
            label1={"User Name"}
            onlyone
            placeholder1={"Enter User Name"}
            value1={id}
            onChange1={(e) => setId(e.target.value)}
          />
          <CustomInput
            label1={"Password"}
            onlyone
            placeholder1={"Enter Password"}
            type1={show ? "text" : "password"}
            value1={password}
            onChange1={(e) => setPassword(e.target.value)}
            right={
              !show ? (
                <FiEye cursor={"pointer"} size={18} onClick={handleClick} />
              ) : (
                <FiEyeOff cursor={"pointer"} size={18} onClick={handleClick} />
              )
            }
          />
          {/* <Text align={"right"} mt={2}>
            Forgot Password?
          </Text> */}
          <Center mt={5}>
            <CustomButton
              title={"Login"}
              loading={loading}
              onClick={() => onLogin()}
            />
          </Center>
          {/* <Text align={"center"} mt={2}>
            Create New Account
          </Text> */}
        </Container>
      </Flex>
    </Container>
  );
};

export default Login;

import { Card, Center, Container, Grid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HeadingLayoutAdd } from "../../components/LayoutUtils";
import { useLocation, useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import SelectImage from "../../components/SelectImage";
import CustomButton from "../../components/CustomButton";
import { add_associate } from "../../utils/apis";

const AddAssociate = () => {
  const { state } = useLocation();
  const { type, item } = state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Obj, setObj] = useState({});

  useEffect(() => {
    if (type == "Edit") {
      setObj(item);
    }
  }, []);

  const validation = () => {
    if (!Obj?.title || (!Obj?.image?.name && type == "Add") || !Obj?.alt_text) {
      setObj({
        ...Obj,
        titleval: !Obj?.title,
        titlemess: !Obj?.title ? "Title is required" : "",

        imageval: !Obj?.image?.name && type == "Add",
        imagemess:
          !Obj?.image?.name && type == "Add" ? "Image is required" : "",

        alt_textval: !Obj?.alt_text,
        alt_textmess: !Obj?.alt_text ? "Alt text is required" : "",
      });
      return;
    } else {
      submitData();
    }
  };

  const submitData = async () => {
      try {
        setLoading(true);
      const body = new FormData();
      body.append("action", type == "Edit" ? "update" : "create");
      if (type == "Edit") {
        body.append("id", item?.id);
      }
      body.append("title", Obj?.title);
      body.append("alt_text", Obj?.alt_text);
      if (Obj?.image?.name) {
        body.append("image", Obj?.image);
      }
      await add_associate(body).then((v) => v.success && navigate(-1));
    } catch (error) {
      console.log("error ===", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    // <div>AddAssociate</div>
    <Container h={"full"} w={"full"} maxW={"full"}>
      <HeadingLayoutAdd type={type} title="Associate" />
      <Card bg={"#fff"} mt={2} p={5}>
        <Grid
          mb={10}
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={3}
          width="100%"
          height="100%"
        >
          <CustomInput
            label1={"Title"}
            placeholder1={"Enter Title"}
            value1={Obj.title}
            onChange1={(e) =>
              setObj({
                ...Obj,
                title: e.target.value,
                titleval: false,
                titlemess: "",
              })
            }
            onlyone
            errorBorder1={Obj.titleval}
            error1={Obj.titlemess}
          />
          <SelectImage
            errorBorder={Obj?.imagemess ? "#f00" : ""}
            error={Obj?.imagemess}
            mt={2}
            label={"Associate Image"}
            filename={Obj?.image?.name}
            onChange={(e) =>
              setObj({ ...Obj, image: e.target.files[0], imagemess: "" })
            }
          />

          <CustomInput
            label1={"Alt Text"}
            placeholder1={"Enter Alt Text"}
            value1={Obj.alt_text}
            onChange1={(e) =>
              setObj({
                ...Obj,
                alt_text: e.target.value,
                alt_textval: false,
                alt_textmess: "",
              })
            }
            onlyone
            errorBorder1={Obj.alt_textval}
            error1={Obj.alt_textmess}
          />
        </Grid>
        <Center mt={10}>
          <CustomButton
            title={"Submit"}
            loading={loading}
            onClick={() => validation()}
          />
        </Center>
      </Card>
    </Container>
  );
};

export default AddAssociate;

import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import CustomButton from "./CustomButton";

const HeadingLayout = ({
  title,
  onClick,
  totalCount,
  sNot = false,
  btnNot = false,
  showTotal=true,
  ListText = "List"  
}) => {
  return (
    <>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>
          {title}
          {sNot ? "" : "s"} {ListText}
        </Heading>
        {!btnNot && (
          <CustomButton
            title={`Add ${title}`}
            // onClick={() => navigate("/add-blog", { state: { type: "Add" } })}
            onClick={onClick}
          />
        )}
      </Flex>
      {showTotal &&
       <Flex gap={2} align={"center"}>
       <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
         Total {title}
         {sNot ? "" : "s"}: {totalCount}
       </Text>
     </Flex>
      }
     
    </>
  );
};

export default HeadingLayout;

import { FaBlog, FaReplyAll, FaUsers } from "react-icons/fa";
import {
  MdOutlineAddHome,
  MdOutlinePermMedia,
  MdPolicy,
  MdQueryStats,
} from "react-icons/md";

export const base_url = "https://digi.pmpframe.com/api/insugo/";

// blog,media,testimonial,career,associate,queries

export const SideBarItems = [
  {
    path: "/",
    icon: MdQueryStats,
    label: "Queries",
    status: true,
    value: "queries",
  },
  { path: "/blog", icon: FaBlog, label: "Blog", status: true, value: "blog" },
  {
    path: "/testimonial",
    icon: FaReplyAll,
    label: "Testimonial",
    status: true,
    value: "testimonial",
  },
  {
    path: "/media",
    icon: MdOutlinePermMedia,
    label: "Media",
    status: true,
    value: "media",
  },
  {
    path: "/associated",
    icon: MdOutlineAddHome,
    label: "Associated",
    status: true,
    value: "associate",
  },
  {
    path: "/careers",
    icon: FaReplyAll,
    label: "Careers",
    status: true,
    value: "career",
  },
  {
    path: "/policy",
    icon: MdPolicy,
    label: "Policy",
    status: true,
    value: "policy",
  },
  { path: "/users", icon: FaUsers, label: "Users", status: false },
];

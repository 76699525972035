import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import useFetchApi from "../../Hooks/useFetchApi";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import ImageModal from "../../components/ImageModal";
import { toast } from "react-toastify";
import store from "../../redux/store";
import TableHeader from "../../components/TableHeader";
import useTableControls from "../../Hooks/useTableControls";
import { delete_blog } from "../../redux/slice/BlogSlice";
import useDeleteItem from "../../Hooks/useDeleteItem";
import HeadingLayout from "../../components/HeadingLayout";
import { delete_media } from "../../redux/slice/otherSlice";

const Media = () => {
  const {
    rows,
    setRows,
    page,
    setPage,
    search,
    setSearch,
    debouncedSearch,
    id,
    setId,
    url,
    setUrl,
    text,
    setText,
  } = useTableControls();

  // Hooks
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();

  const {
    data,
    isLoading,

    refetch: handleFetchData,
  } = useFetchApi({
    path: "media.php",
    formDataArr: {
      action: "list",
      limit: rows,
      page: page,
      value: debouncedSearch,
    },
  });

  //   action:list
  // page:1
  // limit:10
  // value:
  // action:web_list

  const { onDelete } = useDeleteItem(handleFetchData, delete_media, id);

  useEffect(() => {
    handleFetchData();
  }, [page, rows, debouncedSearch]);

  const navigate = useNavigate();

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <HeadingLayout
        title={"Media"}
        sNot={true}
        onClick={() => navigate("/add-media", { state: { type: "Add" } })}
        totalCount={data?.totalCount}
      />

      {data?.totalCount > 0 && (
        <TableHeader
          filter={<></>}
          exprtData={false}
          placeholder={`Search Media`}
          value={search}
          onSerchChange={(v) => {
            setPage(1);
            setRows(10);
            setSearch(v.target.value);
          }}
        />
      )}

      {isLoading && data?.data?.length === 0 ? (
        <Loader />
      ) : data?.data?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          pagination={true}
          initialValue={page * rows - rows + 1}
          rows={rows}
          page={page}
          onNext={() => {
            if (
              page <
              Math.ceil((search ? data?.searchCount : data?.totalCount) / rows)
            ) {
              setPage(page + 1);
            }
          }}
          onPrevious={() => {
            if (page > 1) {
              setPage(page - 1);
            }
          }}
          onChange={(e) => {
            setPage(1);
            setRows(e.target.value);
          }}
          total_value={search ? data?.searchCount : data?.totalCount}
          headData={["S.No.", "Title", "Image / Video", "Alt Text", "Actions"]}
          body={data?.data?.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + 1 + rows * (page - 1)}</Td>
              <Td textAlign={"center"}>{v?.title}</Td>
              <Td textAlign={"center"} cursor={"pointer"}>
  <Center>
    <div
      onClick={(e) => {
        e.preventDefault();
        onViewOpen();
        setText(v?.type);
        setUrl(v?.type === "video" ? v?.video_url : v?.image);
      }}
      style={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100px",
        height: "100px",
        overflow: "hidden",
      }}
    >
      {v?.type === "video" ? (
        v?.video_url?.includes("<iframe") ? (
          <div
            dangerouslySetInnerHTML={{
              __html: v?.video_url.replace(
                /<iframe /,
                '<iframe style="width:100px; height:100px; pointer-events: none; cursor:pointer;" '
              ),
            }}
          />
        ) : (
          <></>
        )
      ) : (
        <img width={"100px"} src={v?.image} alt={v?.alt_text} />
      )}
    </div>
  </Center>
</Td>


              <Td textAlign={"center"}>{v?.alt_text}</Td>
              {/* <Td
                textAlign={"center"}
                cursor={"pointer"}
                onClick={() => {
                  if (v?.video_url.includes("<iframe")) {
                    onViewOpen();
                    setText("video");
                    setUrl(v?.video_url);
                  } else if (v?.video_url.includes("https://www.youtube.com")) {
                    window.open(v?.video_url, "_blank");
                  } else {
                    toast.error("This Link is not Playable ");
                  }
                }}
              >
                <Center>
                  <img width={"100px"} src={v?.image} alt={v?.alt_text} />
                </Center>
              </Td> */}
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      onOpen();
                      setId(v?.id);
                    }}
                  />
                  <FiEdit3
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      navigate("/add-media", {
                        state: { type: "Edit", item: v },
                      });
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}

      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? You want to delete Media"}
        onClick={() => {
          onDelete();
          onClose();
        }}
      />
      <ImageModal
        vid={text == "video"}
        isOpen={isViewOpen}
        onClose={onViewClose}
        url={url}
      />
    </Container>
  );
};

export default Media;

import { VStack } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { SideBarItems } from "../utils/utils";

const Sidebar = React.memo(() => {
  const loggedInUser = localStorage.getItem("userId");
  const user = loggedInUser ? JSON.parse(loggedInUser) : {}; // Ensure it's an object
  const { permissions, super_admin } = user || {};
  return (
    <VStack align="start">
      {SideBarItems?.map(({ path, icon: Icon, label, value }) => (
        <>
          {(permissions?.includes(value) || super_admin) && (
            <NavLink key={path} to={path} icon={Icon}>
              {label}
            </NavLink>
          )}
        </>
      ))}
    </VStack>
  );
});

const NavLink = ({ to, icon: Icon, children }) => {
  const location = useLocation();
  const isActive = location.pathname.split("/")[1] === to.split("/")[1];
  return (
    <Link to={to} className={isActive ? "activelink" : "link"}>
      <Icon size={20} />
      {children}
    </Link>
  );
};

export default Sidebar;

import { useState } from "react";
import useDebounce from "./useDebounce";
import { useLocation, useNavigate } from "react-router-dom";

const useTableControls = (initialRows = 10, debounceDelay = 800) => {
  const [rows, setRows] = useState(initialRows);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");
  const [url, setUrl] = useState("");
  const [value, setValue] = useState("All");
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false)
  const debouncedSearch = useDebounce(search, debounceDelay);

  const navigate = useNavigate();
  const location = useLocation();

  return {
    rows,
    setRows,
    page,
    setPage,
    search,
    setSearch,
    debouncedSearch,
    id,
    setId,
    url,
    setUrl,
    value,
    setValue,
    text,
    setText,
    loading,setLoading,
    navigate,
    location
  };
};

export default useTableControls;

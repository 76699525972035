import {
  Center,
  Container,
  Flex,
  Image,
  Select,
  Td,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import HeadingLayout from "../../components/HeadingLayout";
import useFetchApi from "../../Hooks/useFetchApi";
import useTableControls from "../../Hooks/useTableControls";
import Loader from "../../components/Loader";
import TableView from "../../components/TableView";
import CustomToolTip from "../../components/CustomTooltip";
import { FiEdit3, FiEye, FiTrash2 } from "react-icons/fi";
import { base_url } from "../../utils/utils";
import { toast } from "react-toastify";
import CustomPopup from "../../components/CustomPopup";
import useDeleteItem from "../../Hooks/useDeleteItem";
import { delete_user } from "../../redux/slice/otherSlice";

const Employee = () => {
  const {
    rows,
    setRows,
    page,
    setPage,
    search,
    setSearch,
    debouncedSearch,
    id,
    setId,
    value,
    setValue,
    text,
    setText,
    loading,
    setLoading,
    navigate,
  } = useTableControls();
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure();
  const {
    data,
    isLoading,
    refetch: handleFetchData,
  } = useFetchApi({
    path: "employee.php",
    formDataArr: {
      action: "list",
      limit: rows,
      page: page,
      type: value,
      value: debouncedSearch,
    },
  });
  useEffect(() => {
    handleFetchData();
  }, [page, rows, debouncedSearch]);

  const update_status = async (id, text) => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "update_status");
      body.append("id", id);
      body.append("status", text);

      const response = await fetch(base_url + "employee.php", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      if (res.success) {
        handleFetchData();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("error ===", error);
    } finally {
      setLoading(false);
    }
  };
    const { onDelete } = useDeleteItem(handleFetchData, delete_user, id);
  
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <HeadingLayout
        title={"Employee"}
        sNot={true}
        btnNot={false}
        onClick={() => navigate("/add-users", { state: { type: "Add" } })}
        totalCount={data?.totalCount}
      />
      {isLoading && loading && data?.data?.length === 0 ? (
        <Loader />
      ) : data?.data?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <>
          <TableView
            pagination={true}
            rows={rows}
            page={page}
            initialValue={page * rows - rows + 1}
            onNext={() => {
              if (
                page <
                Math.ceil(
                  (search ? data?.searchCount : data?.totalCount) / rows
                )
              ) {
                setPage(page + 1);
              }
            }}
            onPrevious={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
            onChange={(e) => {
              setPage(1);
              setRows(e.target.value);
            }}
            total_value={search ? data?.searchCount : data?.totalCount}
            headData={["S.No.", "User Name", "Status", "Action"]}
            body={data?.data?.map((v, i) => (
              <Tr key={i}>
                <Td textAlign={"center"}>{i + 1 + rows * (page - 1)}</Td>
                <Td textAlign={"center"}>{v?.username}</Td>
                <Td textAlign={"center"} w={"36"}>
                  <Select
                    w={"32"}
                    size={"sm"}
                    borderRadius={"md"}
                    value={v.status}
                    onChange={(e) => update_status(v.id, e.target.value)}
                  >
                    <option value="0">In Active</option>
                    <option value="1">Active</option>
                  </Select>
                </Td>
                <Td textAlign={"center"}>
                  <Flex gap={2} justifyContent={"center"}>
                    <CustomToolTip
                      button={
                        <FiEye
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            navigate("/add-users", {
                              state: { type: "View", item: v },
                            });
                          }}
                        />
                      }
                      mess={"View User"}
                    />
                    <CustomToolTip
                      button={
                        <FiEdit3
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            navigate("/add-users", {
                              state: { type: "Edit", item: v },
                            });
                          }}
                        />
                      }
                      mess={"Edit User"}
                    />
                    <CustomToolTip
                      button={
                        <FiTrash2
                          size={20}
                          cursor={"pointer"}
                            onClick={() => {
                              onOpen();
                              setId(v?.id);
                            }}
                        />
                      }
                      mess={"Delete User"}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          />
        </>
      )}
       <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? You want to delete Career"}
        onClick={() => {
          onDelete();
          onClose();
        }}
      />
    </Container>
  );
};

export default Employee;

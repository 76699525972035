import {
  Card,
  Flex,
  Select,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { GrNext, GrPrevious } from "react-icons/gr";

const TableView = React.memo( ({
  headData = [],
  body,
  maxW,
  initialValue,
  rows,
  page,
  onPrevious,
  onNext,
  pagination,
  onChange,
  total_value,
}) => {
  console.log("initialValue ===",initialValue);
  
  return (
    <Card
      borderRadius={5}
      overflow={"hidden"}
      // border={"1px solid #88888822"}
      shadow={"md"}
    >
      <TableContainer
        // bg={"#fff"}
        // borderRadius={5}
        flex={1}
        maxWidth={maxW || "full"}
        w={"full"}
        overflow={"scroll"}
        // border={"1px solid #88888822"}
        // shadow={"sm"}
      >
        <Table variant="simple">
          <Thead bg={"#88888822"}>
            <Tr>
              {headData.map((v, i) => (
                <Th color={"#000"} key={i} textAlign={"center"}>
                  {v}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>{body}</Tbody>
        </Table>
      </TableContainer>
      {pagination && (
        <Flex
          w={"full"}
          justify={["center", "flex-end"]}
          alignItems={["end", "center"]}
          px={3}
          gap={6}
          flexDirection={["column", "row"]}
          py={2}
          backgroundColor={"#fff"}
        >
          <Flex align={"center"} gap={4}>
            <Text>Rows per page:</Text>
            <Select
              placeholder=""
              size={"md"}
              w={"15"}
              value={rows}
              onChange={onChange}
            >
              <option value="10">10</option>
              <option disabled={total_value < 10} value="25">25</option>
              <option disabled={total_value < 25} value="50">50</option>
              <option disabled={total_value < 50} value="100">100</option>
            </Select>
          </Flex>
          <Flex align={"center"} gap={4}>
            <Flex gap={3}>
              <Text>{initialValue}</Text>-
              <Text>
                {rows * page >= total_value ? total_value : rows * page}
              </Text>
              <Text>of {total_value}</Text>
            </Flex>
            <GrPrevious
              cursor={"pointer"}
              style={{
                height: 30,
                width: 30,
                padding: 6,
                borderRadius: 5,
              }}
              onClick={onPrevious}
            />
            <GrNext
              cursor={"pointer"}
              style={{
                height: 30,
                width: 30,
                padding: 6,
                borderRadius: 5,
              }}
              onClick={onNext}
            />
          </Flex>
        </Flex>
      )}
    </Card>
  );
});

export default TableView;

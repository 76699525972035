import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import CustomButton from "./CustomButton";
import { FiDownload } from "react-icons/fi";
import { FaPlay } from "react-icons/fa";
const ImageModal = React.memo(({ url = "", isOpen, onClose, vid = false }) => {
  const [play, setPlay] = useState(true);
  const videoRef = useRef(null);

  const handlePlayClick = (e) => {
    e.stopPropagation(); // Pre
    if (videoRef.current) {
      videoRef.current.play();
      setPlay(true);
    }
  };

  const handlePauseClick = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setPlay(false);
    }
  };

  const [loading, setLoading] = useState(false);
  const downloadImage = async (originalImage) => {
    const image = await fetch(originalImage);
    const nameSplit = originalImage.split("/");
    const duplicateName = nameSplit.pop();

    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        url = "";
        setPlay(false);
      }}
    >
      <ModalOverlay />

      <ModalContent maxW={"container.xl"} h={"2xl"}>
        <ModalCloseButton zIndex={100} bg={"#99999988"} />

        {url?.split(".").pop().trim() == "pdf" && (
          <ModalHeader>
            <Flex justify={"end"} marginRight={10}>
              <CustomButton
                title={"Download"}
                onClick={() => downloadImage(url)}
                icon={<FiDownload />}
              />
            </Flex>
          </ModalHeader>
        )}
        <ModalBody h={"full"} w={"full"} bg={"#fff"} borderRadius={5}>
          {vid ? (
            <>
              {url?.includes("<iframe") ? (
                <div
                  style={{ width: "100%", height: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: url.replace(
                      /<iframe /,
                      '<iframe class="w-full h-full" style="width:100%; height:100%;" '
                    ),
                  }}
                />
              ) : (
                <div className="video-main" onClick={handlePauseClick}>
                  <video
                    ref={videoRef}
                    style={{
                      width: "100%",
                      height: "100%",
                      cursor: play ? "pointer" : "",
                    }}
                    autoPlay={true}
                    loop
                    muted={false}
                    className="background-video"
                  >
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  {!play && (
                    <div className="play-icon" onClick={handlePlayClick}>
                      <FaPlay
                        cursor={"pointer"}
                        style={{ paddingLeft: "3px" }}
                        size={24}
                        color="rgb(255,255,255,.8)"
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              {url?.split(".").pop().trim() == "pdf" ? (
                <iframe
                  src={url}
                  style={{ width: "100%", height: "100%" }}
                  frameBorder="1"
                  loading="eager"
                  onLoadStart={() => setLoading(true)}
                  onLoadedData={() => setLoading(false)}
                ></iframe>
              ) : ["doc", "docx"].includes(
                  url.split(".").pop().trim().toLowerCase()
                ) ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${encodeURIComponent(
                    url
                  )}&embedded=true`}
                  style={{ width: "100%", height: "600px" }}
                  frameBorder="0"
                ></iframe>
              ) : (
                <Image
                  src={url}
                  maxW={"container.xl"}
                  w={"full"}
                  objectFit={"contain"}
                  maxH={"100%"}
                  h={"2xl"}
                />
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

export default ImageModal;

// import {
//   Image,
//   Link,
//   Modal,
//   ModalBody,
//   ModalCloseButton,
//   ModalContent,
//   ModalOverlay,
//   Spinner,
// } from "@chakra-ui/react";
// import React, { useState } from "react";
// const ImageModal = ({ url, isOpen, onClose }) => {
//   const [loading, setLoading] = useState(false);
//   return (
//     <Modal isOpen={isOpen} onClose={onClose}>
//       <ModalOverlay />
//       <ModalContent maxW={"container.xl"} maxH={"2xl"} h={"full"} w={"full"}>
//         <ModalCloseButton zIndex={100} bg={"#99999988"} />

//         <ModalBody h={"full"}>
//           {url.split(/[#?]/)[0].split(".").pop().trim() == "pdf" ? (
//             // <Link
//             //   href="https://docs.google.com/gview?url=http://infolab.stanford.edu/pub/papers/google.pdf"
//             //   // href={`https://docs.google.com/gview?url=${url}`}
//             //   isExternal={false}
//             // ></Link>

//             <iframe
//               // src="https://docs.google.com/gview?url=http://infolab.stanford.edu/pub/papers/google.pdf&embedded=true"
//               src={`https://docs.google.com/gview?url=${url}&embedded=true`}
//               style={{ width: "100%", height: "100%" }}
//               frameborder="1"
//               loading="lazy"
//               onLoadStart={() => setLoading(true)}
//               onLoadedData={() => setLoading(false)}
//             ></iframe>
//           ) : (
//             <Image
//               src={url}
//               maxW={"container.xl"}
//               w={"full"}
//               objectFit={"cover"}
//               maxH={"100%"}
//               h={"full"}
//             />
//           )}
//         </ModalBody>
//       </ModalContent>
//     </Modal>
//   );
// };

// export default ImageModal;

// // <Swiper
// //   navigation={true}
// //   pagination={true}
// //   initialSlide={index}
// //   loop
// //   modules={[Navigation, Pagination]}
// //   className="mySwiper"
// //   style={{
// //     // backgroundColor: "green",
// //     display: "block",
// //     height: "100%",
// //     width: "100%",
// //   }}
// // >
// //   {images.map((v, i) => (
// //     <SwiperSlide
// //       style={{
// //         height: "100%",
// //         width: "100%",
// //         display: "flex",
// //         overflow: "hidden",
// //         // backgroundImage: url(v.url),
// //       }}
// //     >
// //       <Image
// //         src={v.url}
// //         maxW={"container.xl"}
// //         w={"full"}
// //         objectFit={"cover"}
// //         maxH={"100%"}
// //         h={"full"}
// //       />
// //     </SwiperSlide>
// //   ))}
// //   </Swiper>
